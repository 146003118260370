.Modal {
  &--scroll {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    //padding: 20px 25px 104px;

    .file-view {
      &__header {
        position: fixed;
        width: 99%;
        background: linear-gradient(
          180deg,
          #000000 0%,
          rgba(0, 0, 0, 0.73) 0.01%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 1;
        padding: 20px 25px 0;
        height: 120px;
        align-items: flex-start;
      }
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}
.OverlayHard {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1006;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.file-view {
  &__content {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    min-width: 100vw;

    &-preloader {
      width: 100vw;
      height: calc(100vh - 130px);
      justify-content: center;
      align-items: center;
    }
  }

  &__header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 0 50px;

    &-item {
      margin: 0 0 0 20px;

      &:first-child {
        margin: 0;
      }

      &.active {
        &:hover {
          cursor: pointer;
        }
      }

      svg {
        position: relative;
        width: 23px;
        height: 24px;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_full {
        display: flex;
      }
    }

    &-close {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__footer {
    margin-top: 16px;
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    max-height: 30px;
    width: 100%;
  }

  &__go-back-button {
    cursor: pointer;
    width: 25px;
    min-height: 24px;
    text-align: center;
    & > svg {
      & > path {
        fill: white;
      }
    }
  }

  &__title {
    font-family: $sf_pro;
    font-size: 10px;
    line-height: 12px;
    color: $primary_light;
    text-align: center;
    margin-left: 10px;
  }

  &__menu-buttons {
    display: flex;
    position: relative;
  }

  &__actions-icon-vertical {
    width: 26px;
    text-align: center;
    margin-left: 15px;
    cursor: pointer;
  }

  &__context-menu.context-menu {
    top: 37px;
    left: auto;
    right: 0;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: calc(100vh - 130px);

      .icon__img_file {
        width: 50%;
        height: 50%;
      }
    }
  }

  &__excel {
    max-width: 80vw;
    max-height: 90vh;
    margin: 0 auto;
    color: black;
    overflow: scroll;
    table {
      border: 1px solid;
      border-collapse: collapse;
    }
    table tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    table tr:nth-child(odd) {
      background-color: #ffffff;
    }
    table td {
      padding: 5px 10px;
    }
  }
   &__txt{
    #preview-container {
      max-width: calc(80vw - 30px);
      height: calc(90vh - 30px);
      margin: 0 auto;
      color: black;
      background-color: #ffffff;
      overflow: scroll;
      padding: 15px;
  }
   }

  &__document {
    max-width: 100%;
    max-height: 100%;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      position: relative;
    }
  }

  &__audio {
    max-width: 100%;
    max-height: 100%;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: calc(10vh - 72px) auto 0 auto;
    }
  }

  &__video {
    max-width: 100%;
    max-height: 100%;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__button {
    min-height: 48px;
    min-width: 196px;
    display: flex;
    align-items: center;

    &:hover {
      color: $primary_light;
    }

    svg {
      margin-right: 18px;
    }

    &_disabled {
      background-color: #c1c9d9;
      cursor: disabled;
      &:hover {
        background-color: #c1c9d9;
      }
    }
  }

  &__prev {
    position: absolute;
    left: calc(4vw - 36px);
    top: calc(50% - 36px);
    cursor: pointer;
  }

  &__next {
    position: absolute;
    right: calc(4vw - 36px);
    top: calc(50% - 36px);
    cursor: pointer;
  }
}
.disabledPreviewHeaderButton {
  svg {
    cursor: not-allowed;
    path {
      fill: var(--iconDisabledColor);
    }
  }
}
@media (max-width: 480px) {
  .file-view__title {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
