.previewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.previewHeader {
  position: relative;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 18px 0 24px;
}

.previewContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loadingPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
}

.actions {
  display: flex;
  gap: 6px;
  z-index: 1;
}

.actionBtn {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  background-color: var(--buttonBg);
  cursor: pointer;
  color: var(--iconColor);
}

.actionBtn:hover {
  background-color: var(--settingsBtnHover);
  color: var(--baseColor);
}

.fileName {
  font-family: 'SF Pro Text';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0;
  color: #fff;
  align-self: center;
  z-index: 1;
}

.shadowBoxDisplay {
  display: block !important;
}

.topShadowBox {
  display: none;
  height: 120px;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  z-index: 1;
  border-radius: 20px 20px 0 0;
  background: var(--previewBoxShadow);
}

.bottomShadowBox {
  display: none;
  height: 120px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 20px 20px;
  background: var(--previewBoxShadowBot);
}