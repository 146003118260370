@import 'static/assets/styles/global/global-varibles';
.fileName {
  margin-top: 15px;
  color: #fff;
  font-size: 10px;
  line-height: 12px;
  font-family: $sf_pro;
}

.videoVisible {
  display: block;
}
.videoInvisible {
  display: none;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: calc(100vh - 130px);
}

.imageContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &__svg {
    background: none;
    & div {
      svg {
        max-height: calc(100vh - 130px);
        max-width: calc(100vw - 150px);
      }
    }
  }
}

.image {
  height: auto;
  max-height: calc(100vh - 130px);
  max-width: calc(100vw - 150px);
}
.container {
  max-width: 80vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.excelViewer {
  max-width: 80vw;
  max-height: 85vh;
  margin: 0 auto;
  color: black;
  overflow: scroll;
  table {
    border: 1px solid;
    border-collapse: collapse;
  }
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  table tr:nth-child(odd) {
    background-color: #ffffff;
  }
  table td {
    padding: 5px 10px;
  }
}
.panoramaContent {
  width: 100%;
  height: 100%;
}
.panoramaContainer {
  width: 80vw;
  height: 100%;
}
